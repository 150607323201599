body{
  margin:0;
  padding: 0;
}
*{
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6,
p{
  margin: 0;
  padding: 0;
}
img{
  width: 100%;
}
.header--container{
display: flex;
align-items: center;
padding: 1.5em 1.5em;
justify-content: space-between;
background: linear-gradient(90deg, #672280 1.18%, #A626D3 100%);
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;
text-align: right;
 color:white;
}
.icon--text{
  display: flex;
  align-items: center;
  gap: 5px;
}
.nav--img--container{
  width: 30px;
  text-align: center;
}
/* styling of the main page   */
.main--container{
  margin: 2.5em auto 0 auto;
  width: 90%;
  text-align: center;
}

.inputs--container{
  display: flex;
  gap: 20px;
}
.inputs--container input[type="text"]{
  width: 50%;
  padding: 0.5em;
}
.btn--container{
  margin-top: 1em;
}
button{
width: 100%;
background: linear-gradient(90.41deg, #711F8D 1.14%, #A818DA 100%);
border-radius: 5px;
color: white;
padding: 0.5em 0;
font-family: 'Karla';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
/* identical to box height */

text-align: center;
letter-spacing: -0.011em;

color: #FFFFFF;

}
.meme--container{
  margin-top: 1em;
  position:relative;
}
.meme--container img{
  position: absolute;
  height: 300px;
  top: 150px;
  left: 50%; 
  z-index: -1;
  transform: translate(-50%, -50%);
}
.top-text{
  text-shadow: 1px 1px 5px red;
  position: absolute;
  top: 225px;
  left: 50%;
 color: yellowgreen;
  width: 100%;
  transform: translate(-50%, -50%);
}
.bottom-text{
  text-shadow: 1px 1px 5px red;
  color: yellowgreen;
  position: absolute; 
  left: 50%;
  top: 480px; 
  transform: translate(-50%, -50%);
}